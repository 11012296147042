:root {
  --color-green-lighter: #d4e6ce;
  --color-green: #00bf43;
  --color-green-dark: #11a645;
  --color-turquoise: #004f51;
  --color-turquoise-dark: #003a3b;
  --color-beige-light: #faf9f5;
  --color-beige: #f0ebdf;
  --color-beige-dark: #e6e0d1;
}

.field.expiry {
  margin-top: -2.5rem !important;
}

.field.expiry label {
  padding: 0.5rem 0 !important;
}

.field.expiry div {
  margin-left: 0 !important;
}

.paiementCalendar {
  width: 100%;
  margin: 2%;
  margin-left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 0;
  border-radius: 5px;
}
.plans-payments {
  /* width: 40%; */
  /* margin-left: 5%; */
  height: auto;
  text-align: center;
  align-items: center;
}
.radio-payment {
  position: relative;
  align-items: center;
  width: 100%;
  margin: 0%;
  padding: 0%;
  border-radius: 5px;
  background-color: var(--color-white);
}

.Calendrier {
  width: 100%;
  min-width: 240px;
  height: 300px;
  padding: 5% 5% 5% 5%;
  background-color: white;
  color: var(--color-turquoise-dark);
  border-radius: 10px;
}
p.depot {
  text-align: center;
  font-weight: bolder;
}

h5.titleChoice {
  color: var(--color-turquoise-dark);
  font-weight: bolder;
  font-size: large;
}
.forfait-boite-paiement {
  width: 100%;
  min-width: 240px;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  text-align: center;
  color: #ffffff;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.scrollTable {
  height: 200px;
  overflow-y: auto;
  scrollbar-width: thin;
}

.scrollTable::-webkit-scrollbar {
  width: 10px;
  border-radius: 2px;
}
.scrollTable::-webkit-scrollbar-track {
  background-color: darkgrey;
  border-radius: 4px;
}

.scrollTable::-webkit-scrollbar-thumb {
  background-color: #565e5e;
  border-radius: 4px;
}

.table-paiement {
  align-items: center;
  width: 100%;
  min-width: fit-content;
  height: auto;
}

th.tpaiement {
  text-align: center;
  padding-bottom: 5%;
  text-decoration: underline;
  color: var(--color-turquoise-dark);
}
.calendarsCountainer {
  height: fit-content;
}
/*left div */
.paiement-choice {
  width: 55%;
  height: auto;
  border-radius: 5px;
  background-color: var(--color-beige-light);
}
.paiement-choice h6 {
  margin-top: 6px;
  text-align: center;
}

li.calendarChoice {
  font-size: medium;
  font-weight: 600;
  padding: 1px;
  margin: 4%;
  min-width: fit-content;
  text-align: center;
  /* background-color: var(--color-green-lighter); */
  background: transparent;
  border: 1px solid var(--color-green);
  color: var(--color-turquoise);
  border-radius: 4px;
  cursor: pointer;
}
li.calendarChoice:hover {
  font-size: medium;
  font-weight: 600;
  margin: 2%;
  text-align: center;
  color: var(--color-turquoise-dark);
  border-radius: 5px;
}

li.selectedCalendarChoice {
  font-size: medium;
  font-weight: 600;
  margin: 4%;
  text-align: center;
  background-color: var(--color-green);
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.choices-grid {
  grid-template-columns: repeat(1, 1fr);
  width: 100%;
}

.choices-calendar {
  display: none;
}

.payment-buttons {
  flex-direction: column;
}

.payment-container {
  flex-direction: column;
}

.payment-container .payment-left,
.payment-container .payment-right {
  width: 100%;
}

.payment-container .payment-right {
  margin-left: 0;
  margin-top: 1rem;
}

@media screen and (max-width: 950px) {
  .paiement-choice {
    width: 90vw;
  }
  .radio-payment {
    display: none;
  }
}

@media screen and (max-width: 650px) {
  .payment-buttons button {
    padding: 1rem 1.5rem;
  }
  .paiement-choice {
    margin-left: 5%;
    min-width: 90%;
  }
}

.payment-cards {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .payment-container {
    flex-direction: row;
  }

  .payment-container .payment-right {
    margin-top: 0;
    margin-left: 1rem;
  }

  .payment-container .payment-left {
    width: 60%;
  }

  .payment-container .payment-right {
    width: 40%;
  }

  .payment-cards {
    width: 70%;
  }
  .plans-payments {
    width: 40%;
    margin-left: 5%;
    height: auto;
    text-align: center;
    align-items: center;
  }
  .field.expiry label {
    padding: 0.5rem 0.7rem !important;
  }

  .payment-buttons {
    flex-direction: row;
  }

  .field.expiry div {
    margin-left: 10px !important;
  }
  .field.expiry {
    margin-top: 1rem !important;
  }
  .choices-grid {
    width: 60%;
  }
  .choices-calendar {
    width: 40%;
    display: block;
  }
}

.cky-btn-revisit-wrapper {
  position: fixed !important;
}
