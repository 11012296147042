/******** FONTS ********/

/*@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");*/

/* poppins-regular - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(""),
    url("../assets/fonts/poppins-v15-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../assets/fonts/poppins-v15-latin-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* poppins-italic - latin */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local(""),
    url("../assets/fonts/poppins-v15-latin-italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../assets/fonts/poppins-v15-latin-italic.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* poppins-600 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local(""),
    url("../assets/fonts/poppins-v15-latin-600.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../assets/fonts/poppins-v15-latin-600.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: "Kobe";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(""), url("../assets/fonts/kobe11-bold.woff2") format("woff2"),
    url("../assets/fonts/kobe11-bold.woff") format("woff");
}

/* poppins-700 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(""),
    url("../assets/fonts/poppins-v15-latin-700.woff2") format("woff2"),
    url("../assets/fonts/poppins-v15-latin-700.woff") format("woff");
}

/******** VARIABLES ********/
:root {
  --border-radius: 4px;
  --header-height: 72px;
  --gap-site: 25px; /* Gap sourrounding the viewport */
  --gap-x: 30px; /* Horizontal gap using in elements */
  --gap-y: 30px; /* Vertical gap using in elements */
  --primary-font: "Poppins", sans-serif;
  --secondary-font: "Kobe", sans-serif;
  --color-green-lighter: #d4e6ce;
  --color-green-light: #b9f600;
  --color-green: #00bf43;
  --color-green-dark: #11a645;
  --color-turquoise: #004f51;
  --color-turquoise-dark: #003a3b;
  --color-beige-light: #faf9f5;
  --color-beige: #f0ebdf;
  --color-beige-dark: #e6e0d1;
  --color-blue-dark: #1a1934;
  --color-gray: #414143;
  --color-black: #000;
  --color-white: #fff;
  --color-error: #fb0000;
  --base-size: 14px;
  --base-lh: 22px;
  --small-size: 14px; /* Pas de changement en desktop */
  --small-lh: 22px; /* Pas de changement en desktop */
  --h2-size: 30px;
  --h2-lh: 30px;
  --h3-size: 28px;
  --h3-lh: 28px;
  --h4-size: 22px;
  --h4-lh: 18px;
  --h5-size: 17px;
  --h5-lh: 17px;
  --h6-size: 16px;
  --h6-lh: 24px;
  --border-radius: 32px;
  /*  --gap-x:15px;
    --gap-y:50px;


    --h1-size:47px;
    --h1-lh:1em;
    --h2-size:40px;
    --h2-lh:1em;
    --h3-size:26px;
    --h3-lh:1em;
    --h4-size:24px;
    --h4-lh:1.1em;
    --h5-size:15px;
    --h5-lh:1.1em;
    --h6-size:12px;
    --h6-lh:1.1em;
    --cta-size: 21px;*/
}

@media (min-width: 768px) {
  :root {
    --base-size: 16px;
    --base-lh: 24px;
    --h2-size: 56px;
    --h2-lh: 56px;
    --h3-size: 47px;
    --h3-lh: 47px;
    --h4-size: 31px;
    --h4-lh: 31px;
    --h5-size: 20px;
    --h5-lh: 20px;

    /*      --gap-y:70px;
        --header-height: 140px;
        --h1-size:58px;
        --h1-lh:1em;
        --h2-size:56px;
        --h2-lh:1em;
        --h3-size:47px;
        --h3-lh:1em;
        --h4-size:31px;
        --h4-lh:1.1em;
        --h5-size:21px;
        --h5-lh:1.1em;
        --h6-size:16px;
        --h6-lh:1.1em;
        --cta-size: 24px;*/
  }
}

/******** RESET ********/
html,
body,
ul,
ol {
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

#service_button {
  border-color: var(--color-green);
  color: var(--color-green);
  background-color: transparent;
}

#service_button:hover {
  background-color: var(--color-green) !important;
  color: white;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}
img {
  max-width: 100%;
  height: auto;
}

hr {
  border: 1px solid var(--color-beige-dark);
}
/******** BASE ********/
body {
  font-family: var(--primary-font);
  background-color: var(--color-beige-light);
  color: var(--color-gray);
  min-width: 320px;
  font-size: 16px;
  line-height: 24px;
}

html,
body,
#app,
#app > div {
  min-height: 100vh;
}

a {
  color: var(--color-gray);
}
a:hover,
a:focus {
  text-decoration: none;
}

a[role="button"][disabled] {
  pointer-events: none;
  cursor: default !important;
}

h1,
*.h1,
h2,
*.h2,
h3,
*.h3,
h4,
*.h4,
h5,
*.h5,
h6,
*.h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  display: block;
}

h1,
*.h1,
h2,
*.h2,
h3,
*.h3,
h4,
*.h4 {
  font-family: var(--secondary-font);
}
h5,
*.h5,
h6,
*.h6 {
  font-family: var(--primary-font);
}

.input.other::placeholder {
  color: #004f51;
}

.contact-input input::placeholder {
  color: #004f51;
  font-family: var(--primary-font) !important;
}

h1,
*.h1 {
  /* used one time on app title */
  font-size: var(--h3-size);
  line-height: var(--h3-lh);
}

h2,
*.h2 {
  font-size: var(--h2-size);
  line-height: var(--h2-lh);
}

h3,
*.h3 {
  font-size: var(--h3-size);
  line-height: var(--h3-lh);
}

h4,
*.h4 {
  font-size: var(--h4-size);
  line-height: var(--h4-lh);
}

h5,
*.h5 {
  font-size: var(--h5-size);
  line-height: var(--h5-lh);
}

h6,
*.h6,
.price {
  font-size: var(--h6-size);
  line-height: var(--h6-lh);
  letter-spacing: -0.03em;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
}

::-moz-selection {
  /* Code for Firefox */
  color: var(--color-gray);
  background-color: var(--color-green);
}

::selection {
  color: var(--color-gray);
  background-color: var(--color-green);
}

/******** SECTIONS ********/
.container {
  max-width: 1175px !important;
  margin: 0 auto;
}

@media (min-width: 768px) {
  main {
    padding: var(--gap-site);
  }
  .expiration {
    margin-top: 0rem !important;
    margin-bottom: 0 !important;
  }
}

/* section.tab__container:not(:target) {
      display: none;
  } */

section.tab__container {
  display: flex;
}
/*
  section.tab__container {
  min-height: 100vh;
  }*/

/******** GRID ********/
.row {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}
@media (min-width: 768px) {
  .row {
    flex-direction: row;
    /* gap: var(--gap-x); */
  }
}

/******** HEADER ********/
#header {
  height: var(--header-height);
  background: var(--color-gray);
  color: var(--color-white);
}
.flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}

.flex-item:nth-child(1) {
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
}
@media (max-width: 767.98px) {
  /* .flex-item:nth-child(1) {
    display: none;
  } */

  .back-button {
    margin-top: 1rem;
  }
}

.flex-item:nth-child(2) {
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
}

.flex-item:nth-child(3) {
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
}
/*
    .site__logo, .site__title, .site__link, .tab__nav {padding:var(--gap-site) var(--gap-x)}
  */

.site__logo img {
  width: 61px;
  height: 19px;
  vertical-align: middle;
}

.site__title h1 {
  font-size: 28px;
  margin-bottom: 0;
  letter-spacing: -0.275px;
}
.site__title {
  padding: var(--gap-y) var(--gap-site);
}
.site__link {
  padding: 14px var(--gap-site) 14px 0;
}
/* .site__logo a {padding: 16px var(--gap-x);}*/
.site__logo a {
  padding: 16px var(--gap-x);
}
.site__link a {
  color: var(--color-white);
  text-decoration: none;
  padding: 10px 0;
}

@media screen and (min-width: 768px) {
  main .site__title {
    display: none;
  }
  .site__title {
    padding: 12px 0 12px var(--gap-site);
    margin-right: calc(var(--gap-site) * -1);
  }
  .site__logo img {
    width: 107px;
    height: 34px;
  }
}

/******** NAVIGATION ********/
section.tab__container.active {
  /* min-height: 100px;*/
}
a.tab__link {
  display: block;
  margin-bottom: 0;
}

.line {
  position: absolute;
  width: 5rem;
  z-index: -1;
  height: 1px;
  /* background: var(--color-green);  */
  background-color: #eee;
  top: 20%;
  left: 50%;
  /* transform: translate(-50%,-50%); */
}

.line.none {
  display: none;
}

.line-1 {
  background: var(--color-green);
}

.promo-input::placeholder {
  font-size: 0.8rem !important;
  /* background-color: black; */
  /* height: 100%; */
  /* background-color: black; */
}

.line.active {
  position: absolute;
  width: 4.5rem;
  z-index: -1;
  height: 1px;
  background: var(--color-green);
  top: 20%;
  left: 100%;
  transform: translate(-50%, -50%);
}

main a.tab__link {
  padding: 20px var(--gap-site);
  /* height: 25px;/ */
  /* width: 25px; */
}
main a.tab__link.active {
  background: var(--color-beige);
  width: 100%;
}
main a.tab__link.active {
  color: var(--color-black);
}

nav {
  text-align: center;
}
nav a {
  /* background: var(--color-green-lighter); */
  background: var(--color-white);
  color: var(--color-green);
  border-radius: 100%;
  width: 25px;
  height: 25px;
  text-decoration: none;
  line-height: 25px;
}
nav a.tab__link.active {
  background: var(--color-green);
  color: var(--color-white);
}
nav ul {
  list-style: none;
  display: inline-block;
}
nav ul li {
  display: inline-block;
  margin: 0 12px;
}

.back-icon {
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--color-green);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
}

/******** TABS CONTENT ********/
.tab__container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: center;
  align-items: flex-start;
}

.tab__content {
  background: var(--color-beige) !important;
  flex: 1 1;
  padding: 0 var(--gap-site) var(--gap-y);
  position: relative;
}
.active > .tab__content {
  background: var(--color-beige);
  position: relative;
}

.tab__sidebar {
  width: 215px;
}

.tab__info {
  margin-bottom: var(--gap-y);
}
.tab__info-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1em;
}
.tab__info-title h3 {
  margin-bottom: 0;
}
.tab__info-plan {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.tab__info-plan .price {
  line-height: var(--h4-lh);
}
.tab__info-plan .price-calculations {
  display: flex;
  flex-direction: column;
  text-align: right;
}
.tab__info-plan .price-calculations .total-price {
  margin-top: 5px;
  padding-top: 3px;
  border-top: 1px solid #000;
}

.tab__info-content p {
  margin-bottom: 0;
}

.border-top {
  padding-top: var(--gap-x);
  border-top: 1px solid var(--color-green-dark);
}

.mobile__sidebar,
.thankyou__footer {
  width: 100%;
  padding: var(--gap-y) var(--gap-site);
}

.step-in-header {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-green);
  color: var(--color-white);
  border-radius: 50%;
  position: absolute;
  left: 50%;
}

.ball {
  background: var(--color-green);
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  margin-right: 1rem;
  flex-shrink: 0;
  margin-top: 0.3rem !important;
}

@media screen and (min-width: 768px) {
  .tab__content {
    padding-top: var(--gap-y);
    position: relative;
  }
  .tab__container {
    flex-direction: row;
  }
  .tab__sidebar {
    margin-left: var(--gap-x);
  }
  .thankyou__footer {
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (min-width: 1200px) {
  .tab__sidebar {
    width: 346px;
    margin-left: calc(var(--gap-x) * 2);
  }
}

@media (max-width: 767.98px) {
  .tab__container,
  .mobile-only > .tab__link {
    border-bottom: 1px solid var(--color-beige) !important;
    /* background-color: black; */
    background: url(../assets/icons/edit.svg) no-repeat calc(100% - 24px) center !important;
  }
  .tab__sidebar {
    display: none;
  }
  .plans .radio {
    margin-left: 0 !important;
  }
}

@media (max-width: 1023px) {
  /* .flex-item:nth-child(2) {
    display: none;
  } */
  #header .site__title {
    display: none;
  }
  .step-in-header {
    display: none;
  }
}

/******** GMAP ********/
.gmap {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  margin-bottom: 1.5rem;
}

.gmap iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

/******** UTILITY CLASSES ********/

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.desktop-only {
  display: none;
}
.cart-info .text {
  margin: 0;
  padding: 0;
  /* position: absolute; */
  top: 0;
}
.use-current-location {
  display: none !important;
}

.use-current-location-mobile {
  display: block;
}

.use-current-location input::placeholder {
  color: #004f51;
  font-weight: 100;
}

.address-input input::placeholder {
  color: #004f51;
  font-weight: 100;
}

.column.address {
  margin: 0px 0px 1rem !important;
}

.cart-info {
  flex-direction: column;
  align-items: flex-start !important;
}

.cart-info .column.is-half {
  width: 100%;
}

.tab__container.thanks .tab__sidebar {
  display: block;
  width: 100%;
  padding: 1rem;
}

.tab__container.thanks .tab__content .back-button {
  margin-top: 0.5rem;
}

.tab__container.thanks .tab__content a {
  margin-top: 0.5rem;
}

.service-heading {
  font-size: 1.2rem !important;
}

#price-single {
  margin: 1rem 0 !important;
}

.card-content.other .content {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}

.address-input {
  display: flex;
  flex-direction: column;
}

.address-input .address-button {
  width: 65%;
  justify-self: flex-end;
  align-self: flex-end;
  margin-top: 1rem;
}

.address-input .address-button.current {
  display: none;
}

.tab__nav {
  display: none;
}

@media screen and (min-width: 900px) {
  .tab__nav {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  .address-input {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
  .address-input .input-input {
    width: 65%;
    margin-right: 1rem;
  }
  .address-input .input-input.current {
    width: 100%;
    margin-right: 0;
  }
  .address-input .address-button {
    width: 35%;
    margin-top: 0;
  }
  .card-content.other .content {
    grid-template-columns: repeat(1, 1fr);
  }
  #price-single {
    margin: 0 !important;
    margin-top: -0.5rem !important;
  }
  .service-heading {
    font-size: 1.5rem !important;
  }
  .tab__container.thanks .tab__content .back-button {
    margin-top: 0;
  }
  .tab__container.thanks .tab__content a {
    margin-top: 0;
  }
  .cart-info .text {
    position: absolute;
    top: 1rem;
  }
  .mobile-only {
    display: none;
  }
  .desktop-only {
    display: block;
  }
  .radio.service.active {
    position: relative;
    bottom: 2rem;
  }
  .use-current-location {
    display: flex !important;
  }
  .use-current-location-mobile {
    display: none;
  }
  .control.discounts {
    grid-template-columns: repeat(6, 1fr) !important;
  }
  .column.address {
    margin: 0px 1rem 1rem 0px !important;
  }
  .tab__content {
    padding-bottom: 0;
  }
  .cart-info {
    flex-direction: row;
    align-items: flex-start;
  }
  .cart-info-summary {
    padding: 1rem 1rem;
    margin-top: 1rem;
    margin-left: 1rem;
  }
  .cart-info .column.is-half {
    width: 50%;
  }
  .tab__container.thanks .tab__sidebar {
    width: 346px;
  }
}

.icon-edit {
  background: url(../assets/icons/edit.svg) no-repeat center center;
  width: 24px;
  height: 24px;
}

.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-error {
  color: var(--color-error);
}
.text-small {
  font-size: var(--small-size);
  line-height: var(--small-lh);
}

.text-info {
  /* background: url(../assets/icons/chat-info.svg) no-repeat top right; */
  /* padding: 7px 40px 0 26px; */
  width: calc(100% - 15px);
}
@media screen and (min-width: 768px) {
  .text-info {
    background-size: 30px;
    padding-right: 50px;
  }
}

.text-green,
.price {
  color: var(--color-green);
}
.text-turquoise {
  color: var(--color-turquoise);
}

.nowrap {
  white-space: nowrap;
}

.p-y {
  padding-top: var(--gap-y);
  padding-bottom: var(--gap-y);
}
.p-x {
  padding-left: var(--gap-x);
  padding-right: var(--gap-x);
}

.p-y-site {
  padding-top: var(--gap-site);
  padding-bottom: var(--gap-site);
}
.p-x-site {
  padding-left: var(--gap-site);
  padding-right: var(--gap-site);
}

.p-y-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.p-x-0 {
  padding-left: 0;
  padding-right: 0;
}

.pt-0 {
  padding-top: 0;
}
.pr-0 {
  padding-right: 0;
}
.pb-0 {
  padding-bottom: 0;
}
.pl-0 {
  padding-left: 0;
}

.w-700 {
  max-width: 700px;
}

footer#footer {
  opacity: 0.5;
}

/******** THANKYOU PAGE ********/

.thankyou {
  margin-top: calc(var(--gap-site) * 2);
  background-color: var(--color-beige);
  padding: calc(var(--gap-site) * 2) var(--gap-site);
}
.thankyou__icon {
  padding: calc(var(--gap-site) * 2);
}

/******** ANIMATIONS ********/
h6,
a:not(.tab__link),
input,
label,
button,
.combo-input,
.textarea,
.textarea:focus-within,
svg path,
.checkbox input + label::after,
.checkbox input:focus + label::after,
.radio input + label::after,
.radio input:focus + label::after {
  transition: all 0.3s ease-in-out;
}

/* Reset */
input,
textarea,
select,
button,
.combo-input {
  display: block;
  max-width: 100%;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
}
input[type="radio"],
input[type="checkbox"] {
  margin: 0;
}
label {
  display: table;
}
input {
  -webkit-appearance: none;
  -moz-appearance: none;
}
button {
  line-height: inherit;
}
button::-moz-focus-inner {
  border: 0;
} /*--- for Firefox ---*/
fieldset,
fieldset legend {
  border: 0;
  padding: 0;
  margin: 1rem 0;
}

/* Form BASE */

.field,
.textarea {
  margin-bottom: 2em;
}

input,
.textarea,
.combo-input {
  border: 2px solid transparent;
  background-color: var(--color-white);
  width: 100%;
  font-weight: 600;
  /*text-transform: uppercase;*/
  color: var(--color-turquoise);
  outline-color: transparent;
  outline-style: none;
  outline: none;
  border-radius: var(--border-radius);
  /* border: 1px solid #004F51; */
}

.textarea {
  padding: 15px 25px;
  border-radius: 30px;
  display: inline-block;
}

.text {
  color: var(--color-gray);
  text-decoration: underline;
  font-size: 1.4rem;
  margin-top: 2rem;
  margin-left: 1rem;
}

textarea {
  width: 100%;
  min-height: 100px;
  resize: vertical;
}
textarea,
textarea:focus {
  border: 0;
  outline: 0;
}

input:valid:focus,
.textarea:focus-within,
.combo-input:focus,
.checkbox input:focus + label::after,
.radio input:focus + label::after {
  /* border-color: var(--color-green); */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  /* box-shadow: 0px 1px 16px rgba(255, 255, 255, 0.05) */
}

input:invalid,
textarea:invalid,
.invalid {
  border-color: var(--color-error);
}

input:invalid:focus,
textarea:invalid:focus {
  border: 2px solid var(--color-error) !important;
  box-shadow: rgba(251, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(251, 0, 0, 0.05) 0px 4px 6px -2px;
}

::-webkit-input-placeholder {
  color: rgba(0, 79, 81, 0.35);
}

::placeholder {
  color: rgba(0, 79, 81, 0.35);
}

label {
  color: var(--color-turquoise);
  font-weight: 700;
}

.credit-card {
  background: no-repeat right center;

  width: calc(100% - var(--gap-x));
}
.credit-card.visa {
  background-image: url("../assets/icons/visa.svg");
  background-size: auto 18px;
}
.credit-card.mastercard {
  background-image: url("../assets/icons/mastercard.svg");
  background-size: auto 30px;
}

.stripe-logo {
  max-width: 111px;
}

@media screen and (min-width: 768px) {
  .stripe-logo {
    max-width: 160px;
  }
}

label,
.text-description,
.selected-options {
  padding: 10px 10px 10px 28px;
}

.text-error {
  /* padding: 10px 10px 0 28px; */
  padding: 10px 0;
}

.text-description {
  color: rgba(65, 65, 67, 0.5);
}

.remove-button {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

input[type="search"] {
  padding: 8px 50px 8px;
  background: var(--color-white) url(../assets/icons/research.svg) no-repeat
    15px center;
}

.payment-cart-service {
  font-size: 1.5rem;
}

.input-search {
  position: relative;
}

.input-search.success:after,
.input-search.success:after,
.input-search.error:after,
.input-search.error:after {
  content: "";
  background: url(../assets/icons/check.svg) no-repeat center center;
  background-size: contain;
  width: 21px;
  height: 18px;
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -9px;
}
.input-search.error:after,
.input-search.error:after {
  background-image: url(../assets/icons/cancel.svg);
}

.input-search input {
  position: relative;
}

/* CHECKBOXES*/
/* inspiration: https://webdesign.tutsplus.com/tutorials/how-to-make-custom-accessible-checkboxes-and-radio-buttons--cms-32074*/
.checkbox,
.radio {
  position: relative;
}

/*style label to give some more space*/
.checkbox label,
.radio label {
  display: block;
  padding: 12px 0 12px 38px;
  font-weight: 400;
  /*font-size: var(--small-size);
    line-height: var(--small-lh);*/
}

.checkbox label strong,
.radio label strong {
  display: block;
  font-weight: 700;
}

.checkbox label {
  color: #363636;
}

/*style and hide original checkbox*/
.checkbox input,
.radio input {
  height: 20px;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 13px;
  width: 20px;
}

/*position new box*/
.checkbox input + label::before,
.radio input + label::before {
  border: 2px solid var(--color-green);
  content: "";
  left: 0;

  position: absolute;
  top: 13px;
}

.checkbox input + label::before {
  width: 20px;
  height: 20px;
}

.radio input + label::before,
.radio input + label::after {
  border-radius: 100%;
}

/*create check symbol with pseudo element*/
.checkbox input + label::after,
.radio input + label::after {
  content: "";
  /*border: 4px solid;
  border-left: 0;
  border-top: 0;*/
  height: 20px;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 13px;
  transition: opacity 0.2s ease-in-out;
  width: 20px;
  background: url(../assets/icons/check-square.svg) no-repeat center center;
}

.radio input + label::after {
  background: url(../assets/icons/check-circle.svg) no-repeat center center;
}

/*reveal check for 'on' state*/
.checkbox input:checked + label::after,
.radio input:checked + label::after {
  opacity: 1;
}

.radio {
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.02);
  border-radius: 4px;
}

.radio.opacity-0 input:checked + label::after {
  opacity: 0 !important;
}

.input-padding {
  padding: 0 !important;
  margin-bottom: 0.5rem;
}

/*focus styles—commented out for the tutorial, but you’ll need to add them for proper use*/
.checkbox input:focus + label::before,
.radio input:focus + label::before {
  border-color: var(--color-green-dark);
}

/* BUTTONS */

button,
a[role="button"],
input,
.combo-input {
  text-decoration: none;
  /* border-radius: 30px; */
  display: inline-block;
  padding: 8px 15px;
  font-weight: 200;
}

.btn-full {
  width: 100%;
  display: block;
}

button,
a[role="button"],
.btn-primary,
a[role="button"][disabled]:hover,
a[role="button"][disabled]:focus {
  border: 0;
  padding: 12px 50px;
  background-color: var(--color-green);
  color: var(--color-white);
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  outline: 0;
}
button svg path,
a[role="button"] svg path,
.btn-primary svg path,
button:hover svg path,
a[role="button"]:hover svg path,
.btn-primary:hover svg path,
button:focus svg path,
a[role="button"]:focus svg path,
.btn-primary:focus svg path {
  fill: var(--color-white);
}

button,
.btn-secondary,
button:disabled:hover,
button[disabled]:hover,
button:disabled:focus,
button[disabled]:focus {
  background-color: var(--color-green-light);
  color: var(--color-blue-dark);
}

button svg path,
.btn-secondary svg path {
  fill: var(--color-blue-dark);
}

button svg,
a[role="button"] svg {
  margin-top: -1px;
}

.icon-left svg {
  margin-right: 4px;
}
.icon-right svg {
  margin-left: 4px;
}

button span,
a span,
button svg,
a svg {
  vertical-align: middle;
}

a[role="button"]:hover,
.btn-primary:hover,
a[role="button"]:focus,
.btn-primary:focus,
.remove-option:hover,
.remove-option:focus {
  background-color: var(--color-green-dark);
}

button:hover,
button:focus {
  /* color: var(--color-white); */
  /* background-color: var(--color-blue-dark); */
}

button:disabled,
button[disabled],
a[role="button"][disabled] {
  opacity: 0.35;
}

a[role="button"][disabled],
.btn-primary[disabled] {
  color: var(--color-green-lighter);
}

/*COMBOBOX*/

.combo {
  /*display: block;
      margin-bottom: 1.5em;
      max-width: 400px;*/
  position: relative;
}

.combo::after {
  content: "";
  background: url(../assets/icons/angle-down.svg) no-repeat center center;
  background-size: contain;
  width: 18px;
  height: 14px;
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -7px;

  /*
      border-bottom: 2px solid rgba(0, 0, 0, 0.5);
      border-right: 2px solid rgba(0, 0, 0, 0.5);
      content: '';
      display: block;
      height: 12px;
      pointer-events: none;
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translate(0, -65%) rotate(45deg);
      width: 12px;*/
}

.combo-input {
  /* background-color: #f5f5f5;
      border: 2px solid rgba(0, 0, 0, 0.5);
      border-radius: 4px;
      display: block;
      font-size: 1em;
      min-height: calc(1.4em + 26px);
      padding: 12px 16px 14px;
      text-align: left;
      width: 100%;*/
}

/* .open .combo-input {
      border-radius: 4px 4px 0 0;
    }*/

/* .combo-input:focus {
      border-color: #e60023;
      box-shadow: 0 0 4px 2px #e60023;
      outline: 5px solid transparent;
    }*/

/*
    .combo-label {
      display: block;
      font-size: 20px;
      font-weight: 100;
      margin-bottom: 0.25em;
    }*/

.combo-menu {
  background-color: #f5f5f5;
  border: 1px solid rgba(0, 0, 0, 0.42);
  border-radius: 0 0 4px 4px;
  display: none;
  max-height: 300px;
  overflow-y: scroll;
  left: 0;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 100;
}

.open .combo-menu {
  display: block;
}

.combo-option {
  padding: 10px 12px 12px;
}

.combo-option.option-current,
.combo-option:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.combo-option.option-selected {
  padding-right: 30px;
  position: relative;
}

.combo-option.option-selected::after {
  border-bottom: 2px solid #000;
  border-right: 2px solid #000;
  content: "";
  height: 16px;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translate(0, -50%) rotate(45deg);
  width: 8px;
}

.selected-options {
  list-style-type: none;
  margin: 0;
}

.selected-options li {
  display: inline-block;
  margin-bottom: 5px;
}

.remove-option {
  background-color: var(--color-green);
  border-radius: 5px;
  color: var(--color-white);
  font-size: var(--small-size);
  margin-bottom: 6px;
  margin-right: 10px;
  padding: 2px 26px 2px 10px;
  position: relative;
}

/*
    .remove-option:focus {
      border-color: #baa1dd;
      box-shadow: 0 0 3px 1px #ff3d3d;
      outline: 3px solid transparent;
    }*/

.remove-option::before,
.remove-option::after {
  border-right: 2px solid var(--color-white);
  content: "";
  height: 1em;
  right: 14px;
  position: absolute;
  top: 50%;
  width: 0;
}

.remove-option::before {
  transform: translate(0, -50%) rotate(45deg);
}

.remove-option::after {
  transform: translate(0, -50%) rotate(-45deg);
}

/* PLANS (forfaits) */

.plans {
  display: flex;
  flex-direction: column;
  gap: 25px 0;
  text-align: center;
}

.single-package-grid {
  grid-template-columns: repeat(1, 1fr);
}

.columns.services {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

@media screen and (min-width: 768px) {
  .columns.services {
    grid-template-columns: repeat(4, 1fr);
  }
  .plans {
    flex-direction: row;
    gap: 5px;
  }
  .single-package-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .payment-cart-service {
    font-size: 24px;
  }
}

.plans h2 {
  font-size: 31px;
  line-height: 31px;
}

.plans .radio {
  flex: 1;
  background-color: var(--color-white);
  display: flex;
  /* align-items: center; */
  /* justify-content: flex-start; */
  margin-bottom: 0px;
}

.expiration {
  margin-top: -2rem;
  margin-bottom: 1.5rem;
}

.plans .radio.payment {
  flex: none;
  background-color: var(--color-white);
  display: flex;
  width: 15rem;
  justify-content: center;
  /* background-color: red; */
  /* align-items: center; */
  /* justify-content: flex-start; */
  margin-bottom: 0px;
}

.plans .plan-details {
  width: 100%;
  text-align: center;
  margin: 0;
  position: absolute;
  bottom: -40px;
}

.plans .radio,
.plans .radio input + label::after {
  /* border-radius: 10px; */
}

.plans .radio input + label::before {
  display: none;
}

.plans .radio.service input + label::after {
  display: none;
}

.mask {
  position: relative !important;
}

.mask::after {
  position: absolute;
  content: "";
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.9304971988795518) 100%
  );
  width: 100%;
  height: 5rem;
  top: 15rem;
  left: 0;
  z-index: 22;
}

.plans .radio input + label::after {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 3px solid var(--color-green);
  background-image: none;
  border-radius: 0px;
}

.plans .radio.payment input + label::after {
  border-radius: 4px;
}

.plans .radio label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem 1rem;
}

.service-text {
  color: var(--color-green);
}

.service-btn {
  padding: 0.5rem 1rem;
  background: var(--color-green);
  color: var(--color-white);
  font-size: 0.8rem;
}

@media screen and (min-width: 380px) and (max-width: 767.98px) {
  .plans .radio label {
    display: flex;
    /* width: 300px; */
    align-items: flex-start;
    justify-content: space-between;
    /* text-align: left; */
  }
}

.modal-backdrop {
  background: rgba(255, 255, 255, 0.5);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 22;
}

.modal-box {
  padding: 2rem;
  text-align: center;
  background: rgb(212, 230, 206);
  border-radius: 0.3rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid rgb(17, 166, 69);
  max-width: 30%;
}

.control.discounts {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}
.modal-popup {
  max-width: 40%;
  min-height: 50%;
}

.image-popup {
  position: absolute;
  width: 30%;
  height: auto;
  bottom: 30px;
  right: -30px;
}
.btn-popup {
  margin-top: 20px;
  vertical-align: center;
  border-radius: 40px;
}
.end-popup {
  width: 100%;
  justify-content: center;
}
.control.discounts {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}
.popup-text {
  font-family: "Poppins", sans-serif;
  margin-left: 10%;
  width: 80%;
}
.link-popup {
  color: var(--color-green);
  text-decoration: underline;
}

@media (max-width: 1200px) {
  .modal-box {
    width: 80%;
    max-width: none;
    max-height: 100%;
  }
  .modal-popup {
    width: 80%;
    max-width: none;
    max-height: 100%;
  }
  .image-popup {
    position: absolute;
    width: 30%;
    height: auto;
    bottom: 30px;
    right: -30px;
  }
}

@media (max-width: 800px) {
  .popup-text {
    width: 100%;
    font-size: 1rem;
    margin-left: 0;
  }
}
@media (max-width: 600px) {
  .image-popup {
    display: none;
  }
}

/*
    .plans {
      display: flex;
      flex-flow: row wrap;
    }

    .plans > div {
      flex: 1;
      padding: 0.5rem;
    }

    .plans input[type=radio] {
      display: none;
    }
    .plans input[type=radio]:not(:disabled) ~ label {
      cursor: pointer;
    }
    .plans input[type=radio]:disabled ~ label {
      color: #bcc2bf;
      border-color: #bcc2bf;
      box-shadow: none;
      cursor: not-allowed;
    }

    .plans label {
      height: 100%;
      display: block;
      background: white;
      border: 2px solid #20df80;
      border-radius: 20px;
      padding: 1rem;
      margin-bottom: 1rem;
      text-align: center;
      box-shadow: 0px 3px 10px -2px rgba(161, 170, 166, 0.5);
      position: relative;
    }

    .plans input[type=radio]:checked + label {
      background: #20df80;
      color: white;
      box-shadow: 0px 0px 20px rgba(0, 255, 128, 0.75);
    }
    .plans input[type=radio]:checked + label::after {
      color: #3d3f43;
      font-family: FontAwesome;
      border: 2px solid #1dc973;
      content: "";
      font-size: 24px;
      position: absolute;
      top: -25px;
      left: 50%;
      transform: translateX(-50%);
      height: 50px;
      width: 50px;
      line-height: 50px;
      text-align: center;
      border-radius: 50%;
      background: white;
      box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.25);
    }

    .plans input[type=radio]#control_05:checked + label {
      background: red;
      border-color: red;
    }


    @media only screen and (max-width: 700px) {
      .plans {
        flex-direction: column;
      }
    }*/

.col-wrapper {
  padding: 30px 20px;
  background: #fff;
}

.col-forfait ul {
  margin-bottom: 0;
  padding-left: 27px;
}

.col-forfait ul li {
  list-style: none;
  position: relative;
  line-height: 1.8;
  padding-bottom: 10px;
}

.col-forfait ul li:before {
  width: 12px;
  height: 12px;
  left: -23px;
  top: 8px;
  content: "";
  border-radius: 100%;
  position: absolute;
}

.col-forfait .green-dots li:before {
  background-color: var(--color-green);
}

.col-forfait .green-lighter-dots li:before {
  background-color: var(--color-beige-light);
}

.forfait-boite {
  padding: 40px 15px 40px 15px;
  text-align: center;
  color: #ffffff;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}

h3.forfait-titre {
  font-family: "Kobe", sans-serif;
  text-transform: none;
  font-size: 42px;
}

.secondary-font {
  font-family: "Kobe", sans-serif;
  font-weight: 700;
}

.forfait-boite p {
  font-size: 24px;
  margin-bottom: 0;
}

.bg-turquoise-dark {
  background-color: var(--color-turquoise-dark);
}

.bg-turquoise {
  background-color: var(--color-turquoise);
}

.bg-green {
  background-color: var(--color-green);
}

.bg-green-lighter {
  background-color: var(--color-green-lighter);
}

.bg-beige {
  background-color: var(--color-beige-dark);
}
.CalendrierChoiceName {
  font-size: small;
  color: #565e5e;
}
.CalendrierHead {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
}
.payment-details {
  margin: 0px;
  font-size: 0.9rem;
}
.payment-details-img {
  height: auto;
  left: 0;
  position: absolute;
  top: 0px;
  width: 25%;
  text-align: left;
  padding-top: 5px;
  padding-left: 5px;
}
.visa-img {
  content: url("../assets/icons/visa.svg");
}
.master-img {
  content: url("../assets/icons/mastercard.svg");
}
.fade-text {
  white-space: nowrap; /* prevent text from wrapping */
  overflow: hidden; /* hide the overflow of the element */
  text-overflow: ellipsis; /* add ellipsis when text is truncated */
  width: 190px; /* set the width of the element */
  opacity: 0.65; /* set the initial opacity of the element */
}

/**DATEPICKER**/
.datePicker {
  border-radius: 20px !important;
  margin-bottom: 20px !important;
}
.datePickerFocus {
  border-radius: 20px !important;
  margin-bottom: 20px !important;
  transition: all 0.3s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

/*Box outside*/
.MuiFormControl-root,
.MuiTextField-root,
.css-z3c6am-MuiFormControl-root-MuiTextField-root {
  width: 100% !important;
  border: none !important;
  max-width: 100% !important;
  min-width: 100% !important;
  margin-top: 0px !important;

  background-color: var(--color-white) !important;
  border-radius: 20px !important;
}

/*Box inside*/
.MuiInputBase-root,
.MuiOutlinedInput-root,
.MuiInputBase-colorPrimary,
.MuiInputBase-formControl,
.MuiInputBase-adornedEnd,
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 20px !important;
  margin: -7px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  border: none !important;
  border-style: none !important;
  color: var(--color-turquoise) !important;
}
/*Box inside input*/
.MuiInputBase-root,
.MuiOutlinedInput-root,
.MuiInputBase-colorPrimary,
.MuiInputBase-formControl,
.MuiInputBase-adornedEnd,
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root > input {
  box-shadow: none !important;
}

.MuiOutlinedInput-notchedOutline,
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none !important;
  border-style: none !important;
  border-radius: 20px !important;
  color: var(--color-turquoise) !important;
}

/*Button image*/
.MuiButtonBase-root,
.MuiIconButton-root,
.MuiIconButton-edgeEnd,
.MuiIconButton-sizeMedium,
.css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root > svg path {
  fill: var(--color-turquoise) !important;
}

/**DateCalendar**/
/*header*/
.MuiPickersFadeTransitionGroup-root,
.css-31ca4x-MuiPickersFadeTransitionGroup-root {
  color: var(--color-turquoise);
  font-size: 16px;
  font-weight: bolder;
}
/*button year*/
.MuiPickersYear-yearButton {
  padding-left: 20px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
/*button month*/
.css-bw88rr-MuiPickersMonth-monthButton {
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

/*Selected*/
.Mui-selected {
  background-color: var(--color-green) !important;
}
