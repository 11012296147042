.mobile-only {
  @media (min-width: 768px) {
    display: none;
  }
}
.desktop-only {
  @media (max-width: 767px) {
    display: none;
  }
}

.opportunity-closed-message-div {
  @media (max-width: 767px) {
    width: 100%;
  }
}

.text-right {
  text-align: right;
}
.comment_explanation {
  margin-left: 4%;
  margin-top: 1%;
  color: gray;
}

.overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
.insideOverlay {
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.list-with-bullets {
  list-style: disc !important;
}

.price_refresh_icon {
  position: absolute;
  top: 3px;
  right: 3px;
  cursor: pointer;
}

.package-detail-header {
  @media (min-width: 768px) {
    display: flex;
  }
}

.package-detail-back-button {
  @media (min-width: 768px) {
    position: absolute;
    left: 10px;
  }
}

.cky-btn-revisit-wrapper {
  position: fixed !important;
}
